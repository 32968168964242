@import "~react-md/dist/react-md";
@import './libs/react-mpk/styles/mpk.scss';
@import '~@mdi/font/scss/materialdesignicons.scss';
@import '~material-icons/iconfont/material-icons.css';
@import '~izitoast/dist/css/iziToast.min.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400');
@import '../node_modules/storm-react-diagrams/dist/style.min.css';

@include react-md-utils;

$default-font: "Source Sans Pro", sans-serif;
$decorative-font: "Roboto Slab", serif;
$body-font: "Open Sans", serif !important;

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $default-font;
  // background-color: #eeebe7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $default-font;
}

#root {
  width: 100%;
  height: 100%;
}

.decorative-text {
  font-family: $decorative-font !important;
}

.body-text {
  font-family: $body-font;
  font-weight: 400;

  > * {
    font-family: $body-font;
    font-weight: 400;
  }
}

// --------DIAGRAM--------/
.srd-diagram {
  width: 100%;
  min-height: 400px;
}

.mpk-bluepring-bg {
  background: url('./assets/bluepring-bg.jpg')
}
// -------- END OF DIAGRAM--------/