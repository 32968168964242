div.ag-theme-material, .ag-header-cell-text, .ag-text-field-input {
  font-size: 16px !important;
}

window, body {
    zoom: 80%;
    scale: 1;
    -moz-transform: scale(1);
}

.mpk-full.viewport-height {
  height: 100% !important;
}

.mpk-full.viewport-width {
  width: 100% !important;
}

._loading_overlay_wrapper {
  position: static !important;
}

._loading_overlay_overlay {
  position: fixed !important;
}

.chip-green {
  background: #4caf50;
  color: white !important;
}

.chip-red {
  background: #f44336;
  color: white !important;
}

.dialogInduk {
  width: 600px !important;
}

.progress{
  width : 200px !important;
}

.totalBold input {
  font-weight: bold !important;
}

.inputRight input {
  text-align: right;
}

.react-datepicker-popper {
  z-index: 9999;
}

$gradient-primary-2: #eee;
$gradient-primary-1: #e2e2e2;

.account{
    background: #e2e2e2;
    overflow-x:hidden;
    position:relative;
    .bg{
      position:fixed;
      overflow:hidden;
      width:100%;
      height:100%;
      z-index: 0;
      .parallelogram-1 {
        position:absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity:.54;
        -webkit-transform: skew(56deg);
        -moz-transform: skew(56deg);
        -o-transform: skew(56deg);
        background: $gradient-primary-1; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-1 56%, $gradient-primary-2 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-1 56%,$gradient-primary-2 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-1 56%,$gradient-primary-2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
      }
      .parallelogram-2 {
        position:absolute;
        top:0;
        right:0;
        width: 50%;
        height: 100%;
        opacity:1;
        -webkit-transform: skew(56deg);
        -moz-transform: skew(56deg);
        -o-transform: skew(56deg);
        background: $gradient-primary-2; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-1 0%, $gradient-primary-2 24%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-1 0%,$gradient-primary-2 24%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-1 0%,$gradient-primary-2 24%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
      }
      .parallelogram-3 {
        position:absolute;
        top:0;
        left:0%;
        opacity:.24;
        width: 18%;
        height: 100%;
        -webkit-transform: skew(-12deg);
        -moz-transform: skew(-12deg);
        -o-transform: skew(-12deg);
        background: $gradient-primary-2; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-1 0%, $gradient-primary-2 52%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-1 0%,$gradient-primary-2 52%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-1 0%,$gradient-primary-2 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
      }
      .parallelogram-4 {
        position:absolute;
        top:0;
        right:30%;
        opacity:.32;
        width: 40%;
        height: 100%;
        -webkit-transform: skew(-12deg);
        -moz-transform: skew(-12deg);
        -o-transform: skew(-12deg);
        background: $gradient-primary-2; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-2 0%, $gradient-primary-1 52%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-2 0%,$gradient-primary-1 52%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-2 0%,$gradient-primary-1 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-2', endColorstr='$gradient-primary-1',GradientType=0 ); /* IE6-9 */
      }
      .parallelogram-5 {
        position:absolute;
        top:0;
        right:5%;
        opacity:.4;
        width: 40%;
        height: 100%;
        -webkit-transform: skew(-12deg);
        -moz-transform: skew(-12deg);
        -o-transform: skew(-12deg);
        background: $gradient-primary-2; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-2 0%, $gradient-primary-1 52%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-2 0%,$gradient-primary-1 52%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-2 0%,$gradient-primary-1 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-2', endColorstr='$gradient-primary-1',GradientType=0 ); /* IE6-9 */
      }
    }
}

//Tags Input

.tags {
  display: run-in;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 14px;
  color: black;
  padding-top: 14px;
}

.tags input {
  width: 100%;
  min-width: 50%;
  border: none;
  border-radius: 5px;
  // padding: 14px;
  // padding-left: 0px;
  font-size: 15px;
  color : black;
}

.tags input:focus {
  outline: none !important;
  border:1px solid dodgerblue;
  box-shadow: 0 0 5px #719ECE;
}

.tag {
  background-color: tomato;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  color: white;
}

.tag button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  margin: 0;
  font-size: 90%;
  color: red;
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 20px;
  font-size: 17px;
  color: #b8b8b8;
}
.button-wrap {
  position: relative;
}
.button {
  display: inline-block;
  background-color: darkorchid;
  border-radius: 8px;
  border: 1px solid darkorchid;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  padding: 8px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}
.button:hover {
  background-color: darkorchid;
}