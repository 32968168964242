@import "../../styles/var";

.mpk-appbar {
  background: $mpk-appbar-color;
  height: 64px;
  padding: 0 16px;
  &.inverse {
    // background: #f04438;
    background: #2d962d;
    > *,
    .rmd-button {
      color: white;
    }
  }
}
